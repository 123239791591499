import React, { useState, useEffect } from 'react';
import { isMobile, isAndroid, isIOS } from 'react-device-detect';
import axios from 'axios';
import countryCodes from 'country-codes-list';
import { useRouter } from 'next/router';
import Layout from '../components/Layout';
import {
  Flex,
  Box,
  Button,
  Text,
  Input,
  Select,
  Stack,
} from '@chakra-ui/react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Roboto_Condensed } from 'next/font/google';

import { removeLeadingZero } from '../helpers';
import Seo from '../components/Seo';
import Link from 'next/link';

// Subsets are really important.
const roboto = Roboto_Condensed({
  weight: '400', // if single weight, otherwise you use array like [400, 500, 700],
  style: 'normal', // if single style, otherwise you use array like ['normal', 'italic']
  subsets: ['latin'],
});

const Application = ({
  baseUrl,
  captchaSiteKey,
  basicUser,
  basicPwdOne,
  basicPwdTwo,
}) => {
  const [countries, setCountries] = useState([]);
  const [country_code, setCountryCode] = useState({
    flag: '🇬🇧',
    countryNameEn: 'United Kingdom',
    countryCallingCode: 44,
  });
  const [phone, setPhone] = useState('');
  const [captcha, setCaptcha] = useState('');
  const [validPhone, setValidPhone] = useState(true);
  const [validCaptcha, setValidCaptcha] = useState(true);
  const [sent, setSent] = useState(false);
  const [error, setError] = useState('');

  let { target } = useRouter().query;

  const stringToken = `${basicUser}:${basicPwdOne + basicPwdTwo}`;
  const token = Buffer.from(stringToken).toString('base64');

  const headers = {
    'Content-Type': 'application/json; charset=utf-8',
    Authorization: `Basic ${token}`,
  };

  useEffect(() => {
    if (isMobile) {
      if (isAndroid) {
        window.open(
          'https://play.google.com/store/apps/details?id=com.vert.client'
        );
      }
      if (isIOS) {
        window.open('https://apps.apple.com/gb/app/vert/id1551189328');
      }
    }
  }, []);

  useEffect(() => {
    if (target) {
      if (isMobile) {
        if (isAndroid) {
          axios.post('/api/analytics', {
            target: target,
            os: 'android',
          });
        }
        if (isIOS) {
          axios.post('/api/analytics', {
            target: target,
            os: 'iOS',
          });
        }
      } else {
        axios.post('/api/analytics', {
          target: target,
          os: 'Desktop',
        });
      }
    }
  }, []);

  useEffect(() => {
    const country = countryCodes.all();
    const res = country.sort((a, b) =>
      a.countryNameEn.toLowerCase() < b.countryNameEn.toLowerCase()
        ? -1
        : a.countryNameEn.toLowerCase() > b.countryNameEn.toLowerCase()
        ? 1
        : 0
    );
    setCountries(res);
  }, []);

  const checkPhone = (phone) => {
    let formatedPhone;
    const checkIfZero = phone.charAt(phone.length[0]) === '0';
    if (checkIfZero) {
      formatedPhone = phone.substring(1);
    } else {
      formatedPhone = phone;
    }

    if (formatedPhone.length >= 10) {
      setValidPhone(true);
      return true;
    } else {
      setValidPhone(false);
      return false;
    }
  };

  const onClickSend = () => {
    let isPhoneValid = checkPhone(phone);
    if (!captcha) {
      setValidCaptcha(false);
    } else {
      setValidCaptcha(true);
    }

    if (isPhoneValid && captcha) {
      axios
        .post(
          `${baseUrl}/sms/send`,
          {
            country_code: `+${country_code.countryCallingCode}`,
            phone: removeLeadingZero(phone),
            message:
              'Download our Vert app from https://vert.london/app to book your next executive ride!',
          },
          { headers }
        )
        .then(({ data }) => {
          if (!data.error) {
            setSent(true);

            setTimeout(() => {
              setSent(false);
              setPhone('');
              setCaptcha('');
            }, 2000);
          } else {
            setError(data.message);
          }
        })
        .catch((e) => console.log(e));
    }
  };

  let seo = {
    title: 'Download App',
    metaDescription: `Download Vert's all-electric chauffeur service app and elevate your travel experience in London and Essex. Get our cutting-edge app for easy booking, real-time updates, and exclusive offers. Enjoy luxury chauffeur service at your fingertips.`,
  };

  return (
    <Layout withAppNavBar={false}>
      <Seo seo={seo} />
      <Flex p={20} direction={'column'} h="100vh">
        <Text
          className={roboto.className}
          py={10}
          fontSize={'5xl'}
          textAlign={'center'}
        >
          The Vert App is available on iOS and Android.
        </Text>
        <Flex justifyContent={'center'}>
          <Box>
            {isIOS && (
              <Link href="https://apps.apple.com/gb/app/vert/id1551189328">
                <img
                  src="https://eyesafe-assets.s3.eu-west-2.amazonaws.com/email_assets/App_Store_Badge_US_Black.png"
                  alt="appStore"
                />
              </Link>
            )}
            {isAndroid && (
              <Link href="https://play.google.com/store/apps/details?id=com.vert.client">
                <img
                  src="https://eyesafe-assets.s3.eu-west-2.amazonaws.com/email_assets/Google_Play_Badge_US.png"
                  alt="playStore"
                />
              </Link>
            )}
            {!isAndroid && !isIOS && (
              <Flex direction={'column'} textAlign="center">
                <Text textAlign={'center'}>
                  Enter your phone number to download the app!
                </Text>
                <Stack spacing={3} w={400} alignSelf={'center'}>
                  <Select
                    borderColor={
                      country_code.countryCallingCode ? 'gray.200' : 'red.500'
                    }
                    _focus={{
                      borderColor: 'brand.900',
                    }}
                    focusBorderColor="transparent"
                    value={`${country_code.countryNameEn} +${country_code.countryCallingCode}`}
                    onChange={(e) => {
                      const parts = e.target.value.split(' ');
                      const countryName = parts
                        .slice(0, parts.length - 1)
                        .join(' ');
                      const country_code = parts[parts.length - 1];

                      setCountryCode({
                        flag: countries.find(
                          (country) =>
                            country.countryCallingCode ==
                            country_code.substring(1)
                        ).flag,
                        countryNameEn: countryName,
                        countryCallingCode: Number(country_code),
                      });
                    }}
                    isRequired
                  >
                    {countries.map((country, index) => (
                      <option
                        key={index}
                        value={`${country.countryNameEn} +${country.countryCallingCode}`}
                      >
                        {country.flag} {country.countryNameEn} +
                        {country.countryCallingCode}
                      </option>
                    ))}
                  </Select>
                  <Input
                    name="phone"
                    type="text"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    borderColor={validPhone ? 'gray.200' : 'red.500'}
                    placeholder="Phone"
                    autoComplete="off"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    _focus={{
                      borderColor: 'brand.900',
                    }}
                    focusBorderColor="transparent"
                    isRequired
                  />
                  <ReCAPTCHA
                    sitekey={captchaSiteKey}
                    onChange={(value) => {
                      setCaptcha(value);
                      setValidCaptcha(true);
                    }}
                    onExpired={() => setCaptcha('')}
                  />
                  {!validCaptcha && (
                    <Text textAlign={'left'} fontSize={12} color={'red.500'}>
                      Oops! It seems like you didn&apos;t enter the captcha
                      correctly.
                    </Text>
                  )}
                  {error && (
                    <Text textAlign={'left'} fontSize={12} color={'red.500'}>
                      {error}
                    </Text>
                  )}
                  <Button
                    name="send"
                    onClick={() => onClickSend()}
                    type="submit"
                    backgroundColor={'brand.900'}
                    shadow={'md'}
                    color={'white'}
                    _hover={{
                      opacity: 0.7,
                    }}
                    fontWeight={'medium'}
                    isDisabled={sent}
                  >
                    {sent ? 'Sent' : 'Send SMS'}
                  </Button>
                </Stack>
              </Flex>
            )}
          </Box>
        </Flex>
      </Flex>
    </Layout>
  );
};

export default Application;

export async function getServerSideProps() {
  return {
    props: {
      baseUrl: process.env.NEXT_APP_BASE_URL,
      basicUser: '$2b$10$' + process.env.NEXT_VERT_BASIC_USER,
      basicPwdOne: '$2b$10$' + process.env.NEXT_VERT_BASIC_PWD_PART_ONE,
      basicPwdTwo: process.env.NEXT_VERT_BASIC_PWD_PART_TWO,
      captchaSiteKey: process.env.RECAPTCHA_SITE_KEY,
    },
  };
}
